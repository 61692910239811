@tailwind base;
@tailwind components;
@tailwind utilities;

#main-container {
  background: linear-gradient(pink, lavender);
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 1rem;
}

header {
  height: 60px;
  background-color: pink;
}

#date-container {
  height: 56px;
}

#events-container {
  height: 100%;
  min-height: calc(100vh - 60px - 56px - 30vh);
  overflow-y: scroll;
  padding-bottom: 30vh;
}

#map-container {
  height: 30vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
}

#hotdog-container {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 60px - 56px - 30vh);
  z-index: 999;
}

#new-event-button {
  position: fixed;
  left: 50%;
  bottom: 30vh;
  transform: translate(-50%, 50%);
}